<template>
    <!-- 业务优势start -->
    <div class="productLine">
        <!-- <img class="business_bj" src="@/assets/image/business_bj.png" alt=""> -->
      <div class="main productLine_min">
        <div class="_tit">业务优势</div>
        <div class="productLine_tit2">Business advantage</div>
        <div class="_lin"></div>
        <el-tabs v-model="activeName" >
          <el-tab-pane label="数项专利、数十项著作权" name="index1">
            <template slot="label"
              ><div class="tab_iti">数项专利、数十项著作权</div></template
            >
            <!-- 1 -->
            <div class="productLine_main">
              <img class="img1" src="@/assets/image/business1.png" alt="">
            </div>
          </el-tab-pane>
          <el-tab-pane label="高新技术企业、双软企业" name="index2">
            <template slot="label"
              ><div class="tab_iti">高新技术企业、双软企业</div></template
            >
            <!-- 1 -->
            <div class="productLine_main">
                <img class="img2" src="@/assets/image/business2.png" alt="">
            </div>
          </el-tab-pane>
          <el-tab-pane label="专精特新企业" name="index3">
            <template slot="label"
              ><div class="tab_iti">专精特新企业</div></template
            >
            <!-- 1 -->
            <div class="productLine_main">
                <img class="img2" src="@/assets/image/business3.png" alt="">
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 业务优势end -->
</template>

<script>
export default {
  props: {
    state: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      activeName: "index1"
    }
  }
}
</script>

<style lang="scss" scoped>

// 标题
._tit {
  margin-bottom: 4px;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Regular;
  color: #fff;
  font-weight: 400;
}
._tit2 {
  text-align: center;
  font-family: SFPro-Bold;
  font-size: 24px;
  color: #fff;
  font-weight: 700;
  line-height: 30px;
}
._lin {
  width: 80px;
  border-bottom: 4px solid #fff;
  display: flex;
  justify-content: center;
  margin: 24px auto;
}
    // 业务优势start

.productLine {
    background-image: url('../assets/image/business_bj.png');
  padding-top: 60px;
  padding-bottom: 60px;
}
.productLine_tit2 {
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-weight: 400;
}
/deep/.el-tabs__nav-wrap {
  display: flex;
  justify-content: center;
}
/deep/.el-tabs__active-bar {
  background-color: #fff;
//   margin-bottom: -10px;
}
/deep/.el-tabs__item.is-active {
  color: #fff;
}
// /deep/.el-tabs__nav{
//   margin-bottom: 10px;
// }
/deep/.el-tabs__item {
  padding: 0 50px;
  color: #fff;
}
/deep/.el-tabs__nav-wrap::after{
    height: 1px;
    background: #999;
}
.tab_iti {
  text-align: center;
  line-height: 20px;
  font-size: 16px !important;
}
.productLine_main {
//   position: relative;
  text-align: center;
  margin-top: 20px;
  height: 270px;
//   padding-bottom: 40px;
//   background: #fff;
  .img1{
    height: 266px;
  }
  .img2{
    height: 189px;
  }
}
// 业务优势end

// 手机
@media only screen and (max-width: 760px) {
  // 标题
  ._tit {
    font-size: 20px;
  }
  ._tit2 {
    font-size: 14px;
  }
  ._lin {
    border-bottom: 2px solid #4066f5;
  }
  .productLine_min {
    width: calc(100vw);
    height: 250px;
    .img1{
        height: 85px;
    }
    .img2{
        height: 60px;
    }
  }
  .productLine_imbox-im {
    width: 50%;
  }
}
</style>
