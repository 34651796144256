<template>
  <!-- d-none d-sm-block 大 -->
  <!-- d-sm-none小 -->
  <div class="home">
    <div class="header main">
      <div class="header_left">
        <img height="100%" src="@/assets/image/logo2.png" alt="" />
        <div>爱四季</div>
      </div>
      <div class="header_right d-none d-sm-block">
        <el-menu
          router
          :default-active="$route.path"
          active-text-color="#fff"
          text-color="#fff"
          class="el-menu-demo"
          mode="horizontal"
        >
          <el-menu-item index="/homepage">首页</el-menu-item>
          <el-menu-item index="/productLine">产品线</el-menu-item>
          <el-menu-item index="/application">应用软件</el-menu-item>
          <el-menu-item index="/industry">行业方案</el-menu-item>
          <el-menu-item index="/my">联系我们</el-menu-item>
        </el-menu>
      </div>
      <!-- 弹框 -->
      <div class="header_right d-sm-none">
        <div class="dropdown">
          <div id="dropdownMenuButton1" data-bs-toggle="dropdown">
            <img src="@/assets/image/Banner/c6.png" alt="" />
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <!-- <li class="dropdown_li"></li> -->
            <el-menu
              router
              :default-active="$route.path"
              active-text-color="##0D36CE"
              text-color="##333333 "
              class="el-menu-demo"
            >
              <el-menu-item index="/homepage">首页</el-menu-item>
              <el-menu-item index="/productLine">产品线</el-menu-item>
              <el-menu-item index="/application">应用软件</el-menu-item>
              <el-menu-item index="/industry">行业方案</el-menu-item>
              <el-menu-item index="/my">联系我们</el-menu-item>
            </el-menu>
          </ul>
        </div>
      </div>
    </div>
    <!-- 轮播start -->
    <div class="banner d-none d-sm-block">
      <el-carousel height="600px">
        <el-carousel-item>
          <div class="banner_add main">
           <div class="banner_addpc_tit">智能储存解决方案</div>
           <div style="margin-top: 10px">
             <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div>支持存储介质:Flash、SSD、硬盘</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式:WiFi、以太网</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持远程传输:云+互联网</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持产品形态:网络u盘,网络S5D、网络硬盘、NAS
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持访问APP : Android APP、iOS APP、Windows APP,Mac APP、Linux
                  APP
                </div>
              </div>
           </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/z1.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
             <div class="banner_add main">
           <div class="banner_addpc_tit">手机连线解决方案</div>
           <div style="margin-top: 10px">
             <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div>  支持连接设备:U盘、硬盘、SSD、摄像头</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式: USB传输线</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit"> 支持连接接口: Micro USB、Type-C、Lighting</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                 支持手机系统: Android、iOS
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
               支持访问APP: Andraid APP、iOS APP
                </div>
              </div>
           </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/z4.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
             <div class="banner_add main">
           <div class="banner_addpc_tit">智能家居解决方案</div>
           <div style="margin-top: 10px">
             <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div>支持家居设备:智能传感器/摄像头/门窗/开关/窗帘/小电家等</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式:WiFi Zgbee、NB-loT、蓝牙</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持云平台:亚马逊、涂鸦等</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                 支持访问终端:Andraid、iOS、浏览器
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                 支持访问APP:Andraid APP、iOS APP、WEB
                </div>
              </div>
           </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/z3.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
             <div class="banner_add main">
           <div class="banner_addpc_tit">AI设备周边解决方案</div>
           <div style="margin-top: 10px">
             <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div> 智能传感模块:火、烟、光、温度、湿度、雨、水压、血压、声者、活动、</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div><img src="" alt="" /></div>
                <div style="margin-left: 26px">   时间、物体、CO2等传感器</div>
              </div>
                 <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">智能手势模块:支持AI设备的手势操作</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="boximg_add"><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">  智能传输模块:WiFi、Zigbee、NB-loT、蓝牙</div>
              </div>
           </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/z2.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="banner d-sm-none">
      <el-carousel height="274px">
        <el-carousel-item>
          <div class="banner_add_ph">
            <div>智能储存解决方案</div>
            <div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持存储介质:Flash、SSD、硬盘</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式:WiFi、以太网</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">支持远程传输:云+互联网</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持产品形态:网络u盘,网络S5D、网络硬盘、NAS
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a1.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持访问APP : Android APP、iOS APP、Windows APP,Mac APP、Linux
                  APP
                </div>
              </div>
            </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/c1.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner_add_ph">
            <div>手机连线解决方案</div>
            <div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a3.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持连接设备:U盘、硬盘、SSD、摄像头
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a3.png" alt="" /></div>
                <div class="add_ph_tit">支持连接方式: USB传输线</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a3.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持连接接口: Micro USB、Type-C、Lighting
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a3.png" alt="" /></div>
                <div class="add_ph_tit">支持手机系统: Android、iOS</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a3.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持支拮访问APP: Andraid APP、iOS APP
                </div>
              </div>
            </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/c2.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner_add_ph">
            <div>智能家居解决方案</div>
            <div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a4.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持家居设备:智能传感器/摄像头/门窗/开关/窗帘/小电家等
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a4.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持连接方式:WiFi、Zgbee、NB-loT、蓝牙
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a4.png" alt="" /></div>
                <div class="add_ph_tit">支持云平台:亚马逊、涂鸦等</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a4.png" alt="" /></div>
                <div class="add_ph_tit">支持访问终端:Andraid、iOS、浏览器</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a4.png" alt="" /></div>
                <div class="add_ph_tit">
                  支持访问APP:Andraid APP、iOS APP、WEB
                </div>
              </div>
            </div>
          </div>
          <img class="ba_im" src="@/assets/image/Banner/c4.png" alt="" />
        </el-carousel-item>
        <el-carousel-item>
          <div class="banner_add_ph">
            <div>AI设备周边解决方案</div>
            <div style="margin-top: 12px">
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a2.png" alt="" /></div>
                <div class="add_ph_tit">
                  智能传感模块:火、烟、光、温度、湿度、雨、水压、血压、声者、活动、
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div class="add_ph_tit" style="margin-left: 19px">
                  时间、物体、CO2等传感器
                </div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a2.png" alt="" /></div>
                <div class="add_ph_tit">智能手势模块:支持AI设备的手势操作</div>
              </div>
              <div class="d-flex box_add_ph_tit">
                <div><img src="@/assets/image/Banner/a2.png" alt="" /></div>
                <div class="add_ph_tit">
                  智能传输模块:WiFi、Zigbee、NB-loT、蓝牙
                </div>
              </div>
            </div>
          </div>

          <img class="ba_im" src="@/assets/image/Banner/c3.png" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 轮播end -->

    <!-- 公司简介start -->
    <div class="synopsis main d-none d-sm-block">
      <div class="_tit">公司简介</div>
      <div class="_tit2">Company profile</div>
      <div class="_lin"></div>
      <div class="synopsis_main">
        <div class="synopsis_main_left">
          <div>
            四季宏胜是一家专注于智能存储领域,拥有自主研发、生产的创新型高科技企业。近10年的研发道路上,本着智享品质的原则,为全球知名企业提供多样化的智能解决方案,如:东芝、联想、金士顿、I-O
            DATA、软银、爱国者等国内外40多家知名企业。
            业务上公司严格要求,提出了6+1的指导思想,即:6种能力+服务,为我们的客户赋能,达到3个快速,即:原型快速、试产快速、出单快速。
            公司有强大的研发能力,研发覆盖:硬件设计、固件设计、系统软件设计、手机软件设计、PC软件设计、云服务软件设计,从而实现从端到云的全体系化一站式服务。
            <br/>依托公司在软硬件上强大的研发实力和技术优势，开拓出Iot智能家居产品线，公司提供的方案已成功出货亚马逊，实现单品销量第一。自主化方案提供从硬件模组到软件app及后台服务器的个性化定制需求，实现一站式方案解决。
          </div>
        </div>
        <div class="synopsis_main_right">
          <img
            class="synopsis_main_right_img"
            src="@/assets/image/jianjei/j1.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="synopsis main d-sm-none">
      <div class="_tit">公司简介</div>
      <div class="_tit2">Company profile</div>
      <div class="_lin"></div>
      <div class="synopsis_main">
        <div class="synopsis_main_right">
          <img
            class="synopsis_main_right_img"
            src="@/assets/image/jianjei/j1.png"
            alt=""
          />
        </div>

        <div class="synopsis_main_left">
          <div>
            <div class="synopsis_bom">
              四季宏胜是一家专注于智能存储领域,拥有自主研发、生产的创新型高科技企业。近10年的研发道路上,本着智享品质的原则,为全球知名企业提供多样化的智能解决方案,如:东芝、联想、金士顿、I-O
              DATA、软银、爱国者等国内外40多家知名企业。
            </div>
            <div class="synopsis_bom">
              业务上公司严格要求,提出了6+1的指导思想,即:6种能力+服务,为我们的客户赋能,达到3个快速,即:原型快速、试产快速、出单快速。
            </div>
            <div class="synopsis_bom">
              公司有强大的研发能力,研发覆盖:硬件设计、固件设计、系统软件设计、手机软件设计、PC软件设计、云服务软件设计,从而实现从端到云的全体系化一站式服务。
            </div>
            <div class="synopsis_bom">
              依托公司在软硬件上强大的研发实力和技术优势,开拓出Iot智能家居产品线,公司提供的方案已成功出货亚马逊,实现单品销量第一。自主化方案提供从硬件模组到软件app及后台服务器的个性化定制需求,实现一站式方案解决。
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公司简介end -->
    <!-- 核心优势start -->
    <div class="kernel">
      <div class="kernel_mian main">
        <div class="_tit">核心优势</div>
        <div class="_tit2">Core advantage</div>
        <div class="_lin"></div>
        <div class="d-none d-sm-block">
          <div class="kernel_list ">
            <!-- 1 -->
            <div class="kernel_item">
              <div class="kernel_img">
                <img src="@/assets/image/core01.png" alt="">
                <div class="kernel_img_text">
                  <div>专家团队</div>
                  <div>Team of experts</div>
                </div>
              </div>
              <div class="kernel_text">
                <div style="width: 400px;"><img src="@/assets/image/li.png" alt=""> 深圳、长沙两地建有研发基地</div>
                <div><img src="@/assets/image/li.png" alt=""> 拥有近100人各方面的工程师</div>
                <div style="width: 500px;"><img src="@/assets/image/li.png" alt=""> 师拥有关键专家，如: ID实现、文件系统、存储协议、USB通</div>
              </div>
            </div>
            <!-- 2 -->
            <div class="kernel_item">
              <div class="kernel_img">
                <img src="@/assets/image/core02.png" alt="">
                <div class="kernel_img_text">
                  <div>专业领域</div>
                  <div>Focus on the fields</div>
                </div>
              </div>
              <div class="kernel_text">
                <div><img src="@/assets/image/li.png" alt=""> 智能存储领域</div>
                <div><img src="@/assets/image/li.png" alt=""> 智能可视摄像头领域</div>
                <div><img src="@/assets/image/li.png" alt=""> 智能IoT模组领域</div>
              </div>
            </div>
            <!-- 3 -->
            <div class="kernel_item">
              <div class="kernel_img">
                <img src="@/assets/image/core03.png" alt="">
                <div class="kernel_img_text">
                  <div>业服务7+1</div>
                  <div>Professional services</div>
                </div>
              </div>
              <div class="kernel_text">
                <div><img src="@/assets/image/li.png" alt=""> 产品设计</div>
                <div><img src="@/assets/image/li.png" alt=""> 电脑应用软件设计</div>
                <div><img src="@/assets/image/li.png" alt=""> 硬件设计</div>
                <div><img src="@/assets/image/li.png" alt=""> 云服务支持软件开发</div>
                <div><img src="@/assets/image/li.png" alt=""> 芯片层软件开发</div>
                <div><img src="@/assets/image/li.png" alt=""> 生产实施服务</div>
                <div><img src="@/assets/image/li.png" alt=""> OS层软件开发</div>
              </div>
            </div>
            <!-- 4 -->
            <div class="kernel_item">
              <div class="kernel_img">
                <img src="@/assets/image/core04.png" alt="">
                <div class="kernel_img_text">
                  <div>专心客户</div>
                  <div>ocus on the customer</div>
                </div>
              </div>
              <div class="kernel_text">
                <div style="width:250px"><img src="@/assets/image/li.png" alt=""> 全程VP亲自项目进展并推动</div>
                <div style="width:250px"><img src="@/assets/image/li.png" alt=""> 全程专门业务经理服务订单</div>
                <div style="width:250px"><img src="@/assets/image/li.png" alt=""> 全程专业项目经理跟踪问题</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 手机 -->
      <div class="kernel_list d-sm-none">
        <!-- 1 -->
        <div class="kernel_item">
          <div class="kernel_img">
            <img src="@/assets/image/core01.png" alt="">
            <div class="kernel_img_text">
              <div>专家团队</div>
              <div>Team of experts</div>
            </div>
          </div>
          <div class="kernel_text">
            <div><img src="@/assets/image/li.png" alt=""> 深圳、长沙两地建有研发基地</div>
            <div><img src="@/assets/image/li.png" alt=""> 拥有近100人各方面的工程师</div>
            <div><img src="@/assets/image/li.png" alt=""> 师拥有关键专家，如: ID实现、文件系统、存储协议、USB通</div>
          </div>
        </div>
        <!-- 2 -->
        <div class="kernel_item">
          <div class="kernel_img">
            <img src="@/assets/image/core02.png" alt="">
            <div class="kernel_img_text">
              <div>专业领域</div>
              <div>Focus on the fields</div>
            </div>
          </div>
          <div class="kernel_text">
            <div><img src="@/assets/image/li.png" alt=""> 智能存储领域</div>
            <div><img src="@/assets/image/li.png" alt=""> 智能可视摄像头领域</div>
            <div><img src="@/assets/image/li.png" alt=""> 智能IoT模组领域</div>
          </div>
        </div>
        <!-- 3 -->
        <div class="kernel_item">
          <div class="kernel_img">
            <img src="@/assets/image/core03.png" alt="">
            <div class="kernel_img_text">
              <div>业服务7+1</div>
              <div>Professional services</div>
            </div>
          </div>
          <div class="kernel_text">
            <div><img src="@/assets/image/li.png" alt=""> 产品设计</div>
            <div><img src="@/assets/image/li.png" alt=""> 电脑应用软件设计</div>
            <div><img src="@/assets/image/li.png" alt=""> 硬件设计</div>
            <div><img src="@/assets/image/li.png" alt=""> 云服务支持软件开发</div>
            <div><img src="@/assets/image/li.png" alt=""> 芯片层软件开发</div>
            <div><img src="@/assets/image/li.png" alt=""> 生产实施服务</div>
            <div><img src="@/assets/image/li.png" alt=""> OS层软件开发</div>
          </div>
        </div>
        <!-- 4 -->
        <div class="kernel_item">
          <div class="kernel_img">
            <img src="@/assets/image/core04.png" alt="">
            <div class="kernel_img_text">
              <div>专心客户</div>
              <div>ocus on the customer</div>
            </div>
          </div>
          <div class="kernel_text">
            <div style="width:250px"><img src="@/assets/image/li.png" alt=""> 全程VP亲自项目进展并推动</div>
            <div style="width:250px"><img src="@/assets/image/li.png" alt=""> 全程专门业务经理服务订单</div>
            <div style="width:250px"><img src="@/assets/image/li.png" alt=""> 全程专业项目经理跟踪问题</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 核心优势end -->
    <!-- 业务优势start -->
    <div class="business">
      <div class="main business_min">
        <div class="_tit">业务优势</div>
        <div class="_tit2">Business advantage</div>
        <div class="_lin"></div>
        <div class="business_main d-none d-sm-block">
          <div class="business_main_lin">
            <!-- 1 -->
            <div class="business_box d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(3).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">方案</div>
                <div class="bus_box_right_p">一站式对接服务</div>
              </div>
            </div>
            <!-- 2 -->
            <div class="business_box d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(6).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">质量</div>
                <div class="bus_box_right_p">工厂ISO9001认证</div>
                <div class="bus_box_right_p">产品可以做全球各种品类认证</div>
              </div>
            </div>
            <!-- 3 -->
            <div class="business_box d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(2).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">成本</div>
                <div class="bus_box_right_p">透明报价原则</div>
                <div class="bus_box_right_p">每个部分详细列表，极易把控成本</div>
              </div>
            </div>
            <!-- 4 -->
            <div class="business_box d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(1).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">产品</div>
                <div class="bus_box_right_p">根据客户要求，灵活可定制</div>
              </div>
            </div>
          </div>
          <div class="business_main_lin">
            <!-- 1 -->
            <div class="business_box2 d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(7).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">周期</div>
                <div class="bus_box_right_p">
                  研发周期可控:自有研发团队&nbsp;&nbsp;&nbsp;试产周期可控:自有工厂
                </div>
                <div class="bus_box_right_p">
                  原型周期可控:自有工厂
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;生产周期可控:自有工厂
                </div>
              </div>
            </div>
            <!-- 2 -->
            <div class="business_box3 d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(4).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">生产</div>
                <div class="bus_box_right_p">在东莞、越南拥有配套工厂</div>
                <div class="bus_box_right_p">拥有全球市场的供货能力</div>
              </div>
            </div>
            <!-- 3 -->
            <div class="business_box3 d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(5).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">售后</div>
                <div class="bus_box_right_p">服务期内免费保换</div>
                <div class="bus_box_right_p">维护期保修</div>
              </div>
            </div>
          </div>
        </div>

        <!-- 手机 -->
        <div class="business_main d-sm-none">
          <div class="business_main_lin">
            <!-- 1 -->
            <div class="business_box d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(3).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">方案</div>
                <div class="bus_box_right_p">一站式对接服务</div>
              </div>
            </div>
            <!-- 2 -->
            <div class="business_box d-flex business_box_add">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(6).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">质量</div>
                <div class="bus_box_right_p">工厂ISO9001认证</div>
                <div class="bus_box_right_p">产品可以做全球各种品类认证</div>
              </div>
            </div>
          </div>
          <div class="business_main_lin">
            <!-- 3 -->
            <div class="business_box d-flex">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(2).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">成本</div>
                <div class="bus_box_right_p">透明报价原则</div>
                <div class="bus_box_right_p">每个部分详细列表，极易把控成本</div>
              </div>
            </div>
            <!-- 4 -->
            <div class="business_box d-flex business_box_add">
              <div class="bus_box_left">
                <img class="bus_im" src="@/assets/image/icon(1).png" alt="" />
              </div>
              <div class="bus_box_right">
                <div class="bus_box_right_tit">产品</div>
                <div class="bus_box_right_p">根据客户要求，灵活可定制</div>
              </div>
            </div>
          </div>

          <!-- 1 -->
          <div class="business_box2 d-flex">
            <div class="bus_box_left">
              <img class="bus_im" src="@/assets/image/icon(7).png" alt="" />
            </div>
            <div class="bus_box_right">
              <div class="bus_box_right_tit">周期</div>
              <div class="bus_box_right_p">
                研发周期可控:自有研发团队&nbsp;&nbsp;&nbsp;试产周期可控:自有工厂
              </div>
              <div class="bus_box_right_p">
                原型周期可控:自有工厂
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;生产周期可控:自有工厂
              </div>
            </div>
          </div>
          <!-- 2 -->
          <div class="business_box2 d-flex">
            <div class="bus_box_left">
              <img class="bus_im" src="@/assets/image/icon(4).png" alt="" />
            </div>
            <div class="bus_box_right">
              <div class="bus_box_right_tit">生产</div>
              <div class="bus_box_right_p">在东莞、越南拥有配套工厂</div>
              <div class="bus_box_right_p">拥有全球市场的供货能力</div>
            </div>
          </div>
          <!-- 3 -->
          <div class="business_box2 d-flex">
            <div class="bus_box_left">
              <img class="bus_im" src="@/assets/image/icon(5).png" alt="" />
            </div>
            <div class="bus_box_right">
              <div class="bus_box_right_tit">售后</div>
              <div class="bus_box_right_p">服务期内免费保换</div>
              <div class="bus_box_right_p">维护期保修</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Business></Business>
    <!-- 业务优势end -->

    <!-- 客户优势start -->
    <div class="customer main">
      <div class="_tit">客户优势</div>
      <div class="_tit2">Customer advantage</div>
      <div class="_lin"></div>
      <div class="customer_im d-none d-sm-block">
        <img class="customer_im1" src="@/assets/image/gb.png" alt="" />
      </div>
      <div>
        <img class="customer_im2" src="@/assets/image/map.png" alt="" />
      </div>
    </div>
    <!-- 客户优势end -->

    <!-- 产品线start -->
    <ProDuct></ProDuct>
    <!-- 产品线end -->

    <!-- lot自研start -->
    <div class="kernel">
      <div class="kernel_mian main scroller" style="overflow-x: scroll;">
        <div class="_tit">IoT自研Combo模组规格说明</div>
        <div class="_lin"></div>
        <table>
          <thead>
            <tr>
              <th style="width: 20%;">相关参数</th>
              <th>051模组</th>
              <th>053模组</th>
              <th>060模组</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td style="color:#4066F5">性能配置</td>
              <td>
                <img style="width:60px;height: 39px;" src="@/assets/image/ban.png" alt="" />
                <div style="font-size:16px;font-weight: bold;margin-top: 20px;">内置32位低功耗MCU</div>
                <div style="font-size:12px">主频    - 120MHz</div>
                <div style="font-size:12px">Flash  -  2M byte</div>
                <div style="font-size:12px">SRAM -  256 KB</div>
              </td>
              <td>
                <img style="width:60px;height: 39px;" src="@/assets/image/ban.png" alt="" />
                <div style="font-size:16px;font-weight: bold;margin-top: 20px;">内置32位低功耗MCU</div>
                <div style="font-size:12px">主频    - 120MHz</div>
                <div style="font-size:12px">Flash  -  2M byte</div>
                <div style="font-size:12px">SRAM -  256 KB</div>
              </td>
              <td>
                <img style="width:60px;height: 39px;" src="@/assets/image/ban.png" alt="" />
                <div style="font-size:16px;font-weight: bold;margin-top: 20px;">内置32位低功耗MCU</div>
                <div style="font-size:12px">主频    - 180MHz</div>
                <div style="font-size:12px">Flash  -  2M byte</div>
                <div style="font-size:12px">SRAM -  512 KB</div>
              </td>
            </tr>
            <tr>
              <td style="color:#4066F5">无线特征</td>
              <td>
                <div style="font-size:16px;font-weight: bold;">802.11 b/g/n @2.4GHz</div>
                <div style="font-size:12px;margin-top: 10px;">通道 1-13@2.4GHz</div>
                <div style="font-size:12px">支持 STA/AP/STA+AP 工作模式</div>
                <div style="font-size:12px">802.11 b模式下最大 +16dBm输出功率</div>
                <div style="font-size:12px">支持 WEP、WPA/WPA2、WPA2 PSK</div>
                <div style="font-size:12px">（AES）和 WPA3 安全模式</div>
                <div style="font-size:16px;font-weight: bold;margin-top: 5px;">支持 Bluetooth 5.2</div>
              </td>
              <td>
                <div style="font-size:16px;font-weight: bold;">802.11 b/g/n @2.4GHz</div>
                <div style="font-size:12px;margin-top: 10px;">通道 1-13@2.4GHz</div>
                <div style="font-size:12px">支持 STA/AP/STA+AP 工作模式</div>
                <div style="font-size:12px">802.11 b模式下最大 +16dBm输出功率</div>
                <div style="font-size:12px">支持 WEP、WPA/WPA2、WPA2 PSK</div>
                <div style="font-size:12px">（AES）和 WPA3 安全模式</div>
                <div style="font-size:16px;font-weight: bold;margin-top: 5px;">支持 Bluetooth 5.2</div>
              </td>
              <td>
                <div style="font-size:16px;font-weight: bold;">802.11 b/g/n @2.4GHz</div>
                <div style="font-size:12px;margin-top: 10px;">通道 1-13@2.4GHz</div>
                <div style="font-size:12px">支持 STA/AP/STA+AP 工作模式</div>
                <div style="font-size:12px">802.11 b模式下最大 +16dBm输出功率</div>
                <div style="font-size:12px">支持 WEP、WPA/WPA2、WPA2 PSK</div>
                <div style="font-size:12px">（AES）和 WPA3 安全模式</div>
                <div style="font-size:16px;font-weight: bold;margin-top: 5px;">支持 Bluetooth 4.2</div>
              </td>
            </tr>
            <tr>
              <td style="color:#4066F5">工作电压&温度</td>
              <td>
                <div style="font-size:12px">工作电压：3V-3.6V，典型3.3V</div>
                <div style="font-size:12px">工作温度：-30℃ 到 75℃</div>
              </td>
              <td>
                <div style="font-size:12px">工作电压：4.5V-5.5V，典型5V</div>
                <div style="font-size:12px">工作温度：-30℃ 到 75℃</div>
              </td>
              <td>
                <div style="font-size:12px">工作电压：3.5V-4.2V，典型 3.7V</div>
                <div style="font-size:12px">工作温度：-20℃ 到 85℃</div>
              </td>
            </tr>
            <tr>
              <td style="color:#4066F5">天线</td>
              <td>
                <div style="font-size:16px;font-weight: bold;">支持IPEX 连接器接外接天线</div>
                <div style="font-size:12px">天线增益 2.5dBi@2.4G</div>
              </td>
              <td>
                <div style="font-size:16px;font-weight: bold;">支持IPEX 连接器接外接天线</div>
                <div style="font-size:12px">天线增益 2.5dBi@2.4G</div>
              </td>
              <td>
                <div style="font-size:16px;font-weight: bold;">支持IPEX 连接器接外接天线</div>
                <div style="font-size:12px">天线增益 2.5dBi@2.4G</div>
              </td>
            </tr>
            <tr>
              <td style="color:#4066F5">尺寸封装</td>
              <td>
                <div style="font-size:12px">尺寸、封装对接</div>
                <div style="font-size:12px">14mm (W)×23mm (L) ×4.5mm (H)</div>
                <div style="font-size:12px">封装</div>
                <div style="font-size:12px">DIP - 4PIN焊针</div>
              </td>
              <td>
                <div style="font-size:12px">尺寸、封装对接</div>
                <div style="font-size:12px">26mm (W)×38mm (L) ×7.5mm (H)</div>
                <div style="font-size:12px">封装</div>
                <div style="font-size:12px">P2P - 4PIN座子</div>
              </td>
              <td>
                <div style="font-size:12px">尺寸、封装对接</div>
                <div style="font-size:12px">25mm (W)×28mm (L) ×3.4mm (H)</div>
                <div style="font-size:12px">封装</div>
                <div style="font-size:12px">SMD – 邮票孔</div>
              </td>
            </tr>
            <tr>
              <td style="color:#4066F5">认证</td>
              <td>
                <div style="font-size:12px">FCC</div>
              </td>
              <td>
                <div style="font-size:12px">FCC</div>
              </td>
              <td>
                <div style="font-size:12px">FCC</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- lot自研end -->

    <!-- 应用软件start -->
    <SoftWare></SoftWare>
    <!-- 应用软件end -->
     <!-- SSDstart -->
    <div class="synopsis main d-none d-sm-block">
      <div class="_tit">智美肌肤AI小百科</div>
      <!-- <div class="_tit2">Application FSSD</div> -->
      <div class="_lin"></div>
      <div class="dangdang_main">
        <div class="dangdang_main_left">
          <div>
            脸部皮肤仪检测工具, 提取皮肤照片后交给后台分析审核,并通过小程序展示结果
          </div>
        </div>
        <div class="dangdang_main_right">
          <img
            class="dangdang_main_right_img"
            src="@/assets/image/jianjei/meifu.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="synopsis main d-sm-none">
      <div class="_tit">智美肌肤AI小百科</div>
      <!-- <div class="_tit2">Application FSSD</div> -->
      <div class="_lin"></div>
      <div class="synopsis_main">
        <div class="synopsis_main_right">
          <img
            class="synopsis_main_right_img"
            src="@/assets/image/jianjei/meifu.png"
            alt=""
          />
        </div>

        <div class="synopsis_main_left">
          <div>
            <div class="synopsis_bom">
              脸部皮肤仪检测工具, 提取皮肤照片后交给后台分析审核,并通过小程序展示结果
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SSDend -->
    <!-- SSDstart -->
    <div class="synopsis main d-none d-sm-block">
      <div class="_tit">FSSD</div>
      <div class="_tit2">Application FSSD</div>
      <div class="_lin"></div>
      <div class="dangdang_main">
        <div class="dangdang_main_left">
          <div>
            智能SSD管理工具加密/指纹/生物三种安全模式,提供磁盘内文件增删改查功能
          </div>
        </div>
        <div class="dangdang_main_right">
          <img
            class="dangdang_main_right_img"
            src="@/assets/image/jianjei/SSD.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="synopsis main d-sm-none">
      <div class="_tit">FSSD</div>
      <div class="_tit2">Application FSSD</div>
      <div class="_lin"></div>
      <div class="synopsis_main">
        <div class="synopsis_main_right">
          <img
            class="synopsis_main_right_img"
            src="@/assets/image/jianjei/SSD.png"
            alt=""
          />
        </div>

        <div class="synopsis_main_left">
          <div>
            <div class="synopsis_bom">
              智能SSD管理工具加密/指纹/生物三种安全模式,提供磁盘内文件增删改查功能
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- SSDend -->
    <!-- 当当狸start -->
    <div class="synopsis main d-none d-sm-block">
      <div class="_tit">当当狸</div>
      <div class="_tit2">Application software</div>
      <div class="_lin"></div>
      <div class="dangdang_main">
        <div class="dangdang_main_left">
          <div>
            当当狸是一款集合了社交、商场和文创设备控制等多功能的综合性APP。这款APP旨在打造一个集娱乐、购物、文化创意为一体的智能生活平台，让您的生活更加丰富多彩。

            在社交方面，当当狸提供了全方位的社交体验。您可以轻松创建个人资料，上传照片和视频，分享自己的生活点滴；同时，还可以关注您感兴趣的人和事，与您的朋友实时聊天，分享彼此的喜怒哀乐。

            在商场方面，当当狸拥有丰富的商品类别和品牌，为您提供了广阔的购物选择。无论您需要什么，只需简单搜索，就可以找到您想要的产品；同时，我们还会定期为您推荐优质商品和特价优惠，让您购物更加便捷、高效。

            在文创设备控制方面，当当狸支持各种智能设备，如智能家居、智能穿戴等。您可以方便地控制家中的各种设备，如灯光、空调、电视等；同时，我们还可以为您提供各种智能健康生活方式的建议和指导。
            <br/>总之，当当狸APP是您生活中不可或缺的帮手，让您的生活更加智能化、便捷化、丰富多彩化！
          </div>
        </div>
        <div class="dangdang_main_right">
          <img
            class="dangdang_main_right_img"
            src="@/assets/image/jianjei/dangdang.png"
            alt=""
          />
        </div>
      </div>
    </div>

    <div class="synopsis main d-sm-none">
      <div class="_tit">当当狸</div>
      <div class="_tit2">Application software</div>
      <div class="_lin"></div>
      <div class="synopsis_main">
        <div class="synopsis_main_right">
          <img
            class="synopsis_main_right_img"
            src="@/assets/image/jianjei/dangdang.png"
            alt=""
          />
        </div>

        <div class="synopsis_main_left">
          <div>
            <div class="synopsis_bom">
              当当狸是一款集合了社交、商场和文创设备控制等多功能的综合性APP。这款APP旨在打造一个集娱乐、购物、文化创意为一体的智能生活平台，让您的生活更加丰富多彩。
            </div>
            <div class="synopsis_bom">
              在社交方面，当当狸提供了全方位的社交体验。您可以轻松创建个人资料，上传照片和视频，分享自己的生活点滴；同时，还可以关注您感兴趣的人和事，与您的朋友实时聊天，分享彼此的喜怒哀乐。
            </div>
            <div class="synopsis_bom">
              在商城方面，当当狸拥有丰富的商品类别和品牌，为您提供了广阔的购物选择。无论您需要什么，只需简单搜索，就可以找到您想要的产品；同时，我们还会定期为您推荐优质商品和特价优惠，让您购物更加便捷、高效。
            </div>
            <div class="synopsis_bom">
              在文创设备控制方面，当当狸支持各种智能设备，如智能家居、智能穿戴等。您可以方便地控制家中的各种设备，如灯光、空调、电视等；同时，我们还可以为您提供各种智能健康生活方式的建议和指导。
            </div>
            <div class="synopsis_bom">
              总之，当当狸APP是您生活中不可或缺的帮手，让您的生活更加智能化、便捷化、丰富多彩化！
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 当当狸end -->
    <!-- footer start -->
    <Footer></Footer>
    <!-- footer end -->
  </div>
</template>

<script>
import Footer from '../../components/footer.vue'
import ProDuct from '../../components/product.vue'
import SoftWare from '../../components/software.vue'
import Business from '../../components/business.vue'
export default {
  components: { Footer, SoftWare, ProDuct, Business },
  data() {
    return {
      activeName: "index1"
    }
  }
}
</script>

<style lang="scss" scoped>
/* 表格边框 */
table {
  width: 1200px;
  text-align: center;
  border-collapse: collapse;
}

table td,
table th {
  border: 1px #ddd solid;
  padding: 10px 30px;
}

table th {
  background-color: #f3f3f3;
}

//  当当狸
.dangdang_main{
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
}
.dangdang_main_left {
  padding: 3%;
  padding-right: 4%;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #596580;
  text-align: justify;
  line-height: 30px;
  font-weight: 400;
}
.dangdang_main_right {
  text-align: center;
  padding-bottom: 20px;
  .dangdang_main_right_img{
    width: 80%;
  }
}
// 标题
._tit {
  margin-bottom: 4px;
  text-align: center;
  font-size: 32px;
  font-family: PingFangSC-Regular;
  color: #333333;
  font-weight: 400;
}
._tit2 {
  text-align: center;
  font-family: SFPro-Bold;
  font-size: 24px;
  color: #dddddd;
  font-weight: 700;
  line-height: 30px;
}
._lin {
  width: 80px;
  border-bottom: 4px solid #4066f5;
  display: flex;
  justify-content: center;
  margin: 24px auto;
}

// header start
.el-menu {
  background: rgba($color: #000000, $alpha: 0);
}
/deep/.el-submenu__title:hover,
.el-menu-item:focus,
.el-menu-item:hover {
  background-color: rgba($color: #000000, $alpha: 0) !important;
}
.el-menu.el-menu--horizontal {
  border: none;
}
.header {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
  display: flex;
  // border-bottom:2px solid #fff ;
}

.header_left {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  img{
    width: 70px;
    height: 20px;
  }
  div{
    font-size: 18px;
    color: #FFFFFF;
    margin-left: 15px;
  }
}
.header_right {
  position: relative;
  left: 66%;
  top: 16px;
}
// header end

// 轮播start
.banner {
  max-width: 1920px;
  margin: 0 auto;
}
.ba_im {
  max-width: 1920px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
/deep/.el-carousel__indicators--horizontal {
  display: none;
}

//add
.banner_add {
  // background: #000000;
  position: absolute;
  z-index: 999;
  left: 50%;
  transform: translate(-50%);
  top: 32%;
   letter-spacing: 4px;
  color: #fff;
}
.banner_addpc_tit{
  font-size: 56px;
}
.box_add_ph_tit{
  font-size: 16px;
  height: 34px;
   letter-spacing: -1px;
}
.boximg_add{
  margin-right: 10px;
}


// 轮播end

// 公司简介start
.synopsis {
  padding-top: 60px;
}
.synopsis_main {
  // width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.synopsis_main_left {
  width: 50%;
  padding: 3%;
  padding-right: 4%;
  font-family: PingFangSC-Regular;
  font-size: 16px;
  color: #596580;
  text-align: justify;
  line-height: 30px;
  font-weight: 400;
}
.synopsis_main_right {
  width: 50%;
}
// 公司简介end

//核心优势start
.kernel{
  padding: 60px 0;
}
.kernel_list{
  padding: 0px 0px 0px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.kernel_item{
  width: 585px;
}
.kernel_text{
  padding: 20px 30px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div{
    // max-width: 440px;
    min-width: 228px;
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
    color: #596580;
    img{
      width: 13px;
      height: 13px;
      margin-bottom: 5px;
    }
  }
}
.kernel_img{
  // width: 575px;
  border-radius: 8px;
  position: relative;
  img{
    overflow: hidden;
    width: 100%;
    height: 194px;
    border-radius: 8px;
  }
  .kernel_img_text{
    width: 595px;
    position: absolute;
    z-index: 99;
    top: 0;
    text-align: center;
    margin-top: 50px;
    div:nth-of-type(1){
      font-size: 36px;
      font-weight: 500;
      color: #FFFFFF;
    }
    div:nth-of-type(2){
      font-size: 22px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
//核心优势end

// 业务优势start
.business {
  background: #f5f7fe;
  padding: 60px 0;
}
.business_main_lin {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.business_box {
  width: 288px;
  height: 120px;
  padding: 24px 0 0 24px;
  background-image: url("../../assets/image/y1.png");
}
.business_box2 {
  width: 38%;
  height: 120px;
  padding: 24px 0 0 24px;
  background-image: url("../../assets/image/y2.png");
}
.business_box3 {
  width: 28%;
  height: 120px;
  padding: 24px 0 0 24px;
  background-image: url("../../assets/image/y3.png");
}
.bus_box_right_tit {
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  color: #040849;
  font-weight: 600;
}
.bus_box_right_p {
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #596580;
  font-weight: 400;
}
.bus_im {
  width: 40px;
  margin-right: 20px;
}
// 业务优势end

// 客户优势start
.customer {
  padding-top: 60px;
  position: relative;
}
.customer_im {
  position: absolute;
  z-index: 99;
  left: 72%;
  top: 45%;
}
// 客户优势end


// 手机
@media only screen and (max-width: 760px) {
  // 标题
  ._tit {
    font-size: 20px;
  }
  ._tit2 {
    font-size: 14px;
  }
  ._lin {
    border-bottom: 2px solid #4066f5;
  }
  // tou
  .header {
    width: calc(100vw);
    display: flex;
    justify-content: space-between;
  }
  .header_left {
    height: 28px;
    margin-left: 18px;
    margin-top: -2px;
  }
  .header_right {
    margin-left: 92px;
  }
  .dropdown {
    margin-bottom: 20px;
  }
  .dropdown-menu {
    width: calc(100vw);
    height: calc(100vh);
    background: #fff;
  }
  // banner
  .banner {
    width: calc(100vw);
  }
  .ba_im {
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }
  // 公司简介
  .synopsis {
    width: calc(100vw);
    padding-top: 16px;
  }
  .synopsis_main {
    display: block;
  }
  .synopsis_main_left {
    font-size: 14px;
    width: 100%;
    padding: 0 18px;
    margin-top: 10px;
  }
  .synopsis_main_right {
    width: 100%;
    padding: 0 18px;
  }
  .synopsis_main_right_img {
    width: 100%;
  }
  .synopsis_bom {
    margin-bottom: 10px;
  }
  //核心优势start
  .kernel_mian{
    width: calc(100vw);
  }
.kernel_item{
  width: 100%;
}
.kernel_text{
  padding: 20px 30px 20px 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  div{
    // max-width: 440px;
    // min-width: 228px;
    font-size: 18px;
    font-weight: 400;
    color: #596580;
    img{
      width: 13px;
      height: 13px;
    }
  }
}
.kernel_img{
  // width: 575px;
  border-radius: 8px;
  position: relative;
  img{
    overflow: hidden;
    width: calc(100% - 10px);
    height: 134px;
    border-radius: 8px;
  }
  .kernel_img_text{
    width: 100%;
    position: absolute;
    z-index: 99;
    top: 0;
    text-align: center;
    margin-top: 20px;
    div:nth-of-type(1){
      font-size: 36px;
      font-weight: 500;
      color: #FFFFFF;
    }
    div:nth-of-type(2){
      font-size: 22px;
      font-weight: 400;
      color: #FFFFFF;
    }
  }
}
//核心优势end
  // 业务优势
  .business_min {
    width: calc(100vw);
  }
  .business_box2 {
    width: 100%;
  }
  .business_box_add {
    margin-right: 10px;
  }
  .bus_box_right_tit {
    font-size: 14px;
  }
  .bus_box_right_p {
    font-size: 12px;
  }

  // 客户优势
  .customer {
    width: calc(100vw);
  }
  .customer_im {
    width: 100%;
  }
  .customer_im2 {
    width: 100%;
  }
  .banner_add_ph {
  position: absolute;
  z-index: 999;
  left: 5%;
  top: 38%;
  color: #fff;
}
.add_ph_tit {
  font-size: 10px;
  transform: scale(0.6);
  transform-origin: 0 0;
  white-space: nowrap;
  margin-top: 9px;
  margin-left: 4px;
}
.box_add_ph_tit {
  height: 18px;
}
}
</style>
